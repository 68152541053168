body {
    margin-top: 100px;
    overflow-x: hidden;
}

.card {
    -moz-box-shadow: #010101;
    -webkit-box-shadow: #010101;
    box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
}

.home-card:hover {
    transform: scale(1.05);
}

.process:hover {
    transform: scale(1.04);
}

ul {
    margin: 0px;
    padding: 0px;
}

.footer-section {
    background: #2f3542;
    position: relative;
}

.footer-cta {
    border-bottom: 1px solid #373636;
}

.single-cta i {
    color: #27ae60;
    font-size: 30px;
    float: left;
    margin-top: 8px;
}

.cta-text {
    padding-left: 15px;
    display: inline-block;
}

.cta-text h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}

.cta-text span {
    color: #757575;
    font-size: 15px;
}

.footer-content {
    position: relative;
    z-index: 2;
}

.footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

.footer-logo {
    margin-bottom: 30px;
}

.footer-logo img {
    max-width: 200px;
}

.footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 28px;
}



.footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
}

.footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #27ae60;
}

.footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
}

.footer-widget ul li a:hover {
    color: #27ae60;
}

.footer-widget ul li a {
    color: #878787;
    text-transform: capitalize;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    padding: 13px 20px;
    top: 0;
}

.subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.copyright-area {
    background: #010101;
    padding: 25px 0;
}

.copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
}

.copyright-text p a {
    color: #27ae60;
}

.footer-menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer-menu li:hover a {
    color: #27ae60;
}

.footer-menu li a {
    font-size: 14px;
    color: #878787;
}

.banner {
    transition: all 2s ease-in-out;
}

a {
    text-decoration: none;
    color: #2f3542;
}

a :hover{
    font-weight: bold;
    color: #27ae60;
}

.danger{
    background: #1f495b;
}

.success{
    background-color: #4a94b4;
}

.warning {
    background: #74c9db;
}

.primary {
    background: #1e90ff;
}

.tx-danger{
    color: #ff6348;
}

.tx-success{
    color: #4a94b4;
}

.tx-warning {
    color: #ffa502;
}

.tx-primary {
    color: #1e90ff;
}

.between-danger {
    border: 3px solid #ff6348;
    margin-left:10px;
    margin-right:10px;
}
.between-warning {
    border: 3px solid #ffa502;
    margin-left:10px;
    margin-right:10px;
}
.between-primary {
    border: 3px solid #1e90ff;
    margin-left:10px;
    margin-right:10px;
}
.between-success {
    border: 3px solid #4a94b4;
    margin-left:10px;
    margin-right:10px;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
}

.active {
    font-weight: bold;
}

.the-app-lab {
    color: #27ae60;
}

.the-app-lab:hover {
    color:#ff6348;
}
a:hover {
    color: #fff;
}
